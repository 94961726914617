import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

export enum UserCluster {
  Control = 0, // No experiement
  Cluster1 = 1, // for pricing experiment
  Cluster2 = 2, // Customer in experiment
}

@Serializable()
export class UserClusterData {
  constructor(@JsonProperty({ type: Number }) readonly clusterNum: UserCluster) {}
}
